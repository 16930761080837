div.top-bar {
  display:flex;
  justify-content: space-between;
  width: 95%;
  padding:0.5rem;

  .breadcrumb {
    ul {
      list-style: none;
      li {
        display: inline;
        font-size: 18px;       
      }
      li+li:before {
        padding: 8px;
        color: black;
        content: "/\00a0";
      }
    }
  }

  .navigation {
    display:flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 0.5rem;
    a {
        padding-left: 1rem;
        color: rgb(116, 116, 116);
        :hover {
        color: #2C2C2C;
        cursor: pointer;
        }
    }    
  }
    

}

a.icon_button {
  color: rgb(116, 116, 116);
  :hover {
    color: #2C2C2C;
    cursor: pointer;
  }
}


