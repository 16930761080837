.part-info, .part-stock-container {
    padding: 0.3rem;
    h3 {        
        font-size: 1rem;
        margin: 1rem 0.2rem 0.2rem 0.2rem;
    }
    div.group {
        border: 0.05rem solid #2C2C2C;
        padding: 0.5rem 0.5rem;
        div {
            h4 {
                font-size: 0.9rem;
                width: 100%;
                border-bottom: 0.05rem solid #2C2C2C;
                margin: 0.2rem 0;
            }
            p {
                font-size: 0.9rem;
                margin: 0.2rem 0.5rem 0.5rem 0.5rem;
                min-height: 1rem;
            }
        }
    }    
}
.part-stock-container {
    div.group {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        div:first-child{
            width: 80%;
        }
        a {
            padding-left: 1rem;
        }
    }
}

.part-table {
    width: 100%;
    thead>tr>th {
        background-color: #2C2C2C;
        color: azure;
    }
    tbody {
        tr:nth-child(even) {
            background-color: #f2f2f2;
        }
        tr:hover {
            background-color: #99e7ff;
            cursor: pointer;
        }
    }
    
}

.part-filter {
    input {
        width: 50%;
    }
}

ul.link-list {
    li {
        a {
            cursor: pointer;
            text-decoration: underline;
        }
        margin-bottom: 6px;
    }
}