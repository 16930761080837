div#DrawingContainer {
  background-color: #FFFFFF;
  flex: 1;        

  .part path{
    fill: #DCDCDC;
    stroke: #2C2C2C;
    stroke-width: 2;
    stroke-linecap: butt;
    stroke-linejoin: miter;
  }  
  .part.linked-drawing path {
    fill:#e2e524;
  }
  .part.linked-part path {
    fill:#75e524;
  }
  .part.linked path {
   /* fill:#6e7178; */
  }
  .part.active path{
    fill: #f15858;
    cursor: pointer;
  }



  svg#drawing {
    /*background: rgb(235, 232, 232);*/
    /* border: 1px dotted #DCDCDC; */
  }
}

form.drawing-edit-form {    
    width: 100%;
    padding: 2rem;

    .input-group {
        margin-bottom: 1rem;
        input {
            padding: 0.4rem;
        }
    }
}

ul.drawing-list {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  list-style-type: none;
  padding: 0.5rem;
  li {
    padding: 0.5rem;
    text-align: center;    
    img {
      display: block;
    }
  }

  
  
}